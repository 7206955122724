import React from "react";
import ParentCharityPage from "../../components/ParentWork/ParentCharityPage";

const ParentCharity = () => {
  return (
    <div>
      <ParentCharityPage />
    </div>
  );
};

export default ParentCharity;
