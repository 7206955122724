import React from "react";
import ParentTalkPage from "../../components/ParentWork/ParentTalkPage";

const ParentTalks = () => {
  return (
    <div>
      <ParentTalkPage />
    </div>
  );
};

export default ParentTalks;
