import React from "react";
import SchoolWellbeingPage from "../../components/SchoolWork/SchoolWellbeingPage";

const Culture = () => {
  return (
    <div>
      <SchoolWellbeingPage />
    </div>
  );
};

export default Culture;
