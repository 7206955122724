import React from "react";
import CoachingTeenagers from "../../components/121Coaching/CoachingTeenagers";

const CoachingForTeenagers = () => {
  return (
    <div>
      <CoachingTeenagers />
    </div>
  );
};

export default CoachingForTeenagers;
