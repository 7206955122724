import React from "react";
import SchoolStaffPage from "../../components/SchoolWork/SchoolStaffPage";

const StaffInputs = () => {
  return (
    <div>
      <SchoolStaffPage />
    </div>
  );
};

export default StaffInputs;
