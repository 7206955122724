import React from "react";
import ParentLunchPage from "../../components/ParentWork/ParentLunchPage";

const ParentLunch = () => {
  return (
    <div>
      <ParentLunchPage />
    </div>
  );
};

export default ParentLunch;
