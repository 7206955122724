import React from "react";
import "./ParentAboutTalks.css";
import ParentAboutTalksPage from "../../components/ParentWork/ParentAboutTalksPage";

const ParentAboutTalks = () => {
  return (
    <div>
      <ParentAboutTalksPage />
    </div>
  );
};

export default ParentAboutTalks;
