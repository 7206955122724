import React from "react";
import CoachingParents from "../../components/121Coaching/CoachingParents";

const CoachingForParents = () => {
  return (
    <div>
      <CoachingParents />
    </div>
  );
};

export default CoachingForParents;
